<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="submit_order">
    <van-nav-bar
      title="My cart"
      left-arrow
      :right-text="noticeText"
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="content_area">
        <div class="goods_list">
          <div
            v-for="(item, index) in goodsList"
            :key="index"
            class="goods_item"
          >
            <div class="left">
              <img :src="item.pictures" alt="" srcset="" />
            </div>
            <div class="right">
              <p class="name">
                {{ item.name }}
              </p>
              <div class="all_price">
                <span class="price"
                  ><i>$</i>{{ $filters.priceHandle(item.price) }} *
                  {{ item.num }}</span
                >
                <span class="price"
                  ><i>$</i
                  >{{ $filters.priceHandle(item.price) * item.num }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="noSubmit">
          <!-- nosubmit -->
        </div>
      </div>
      <div class="content_area">
        <van-field
          v-model="zipCode"
          class="zip_code"
          :class="zipCodeRequest ? '' : 'warn_class'"
          type="number"
          label="Zip code"
          placeholder="Please enter the zip code"
          maxlength="5"
          required
          :error-message="zipCodeRequest ? '' : zipCodeError"
        />
        <!-- <van-field class="select_area"
            v-model="areaCode"
            readonly
            clickable
            name="picker"
            label="Select area"
            placeholder="Select area"
            required
            :error-message="areaCodeRequest?'':'Please select area'"
            @click="showAreaPicker = true"
          />
          <van-popup v-model:show="showAreaPicker" position="bottom">
            <van-picker
              :columns="areaColumns"
              @confirm="onAreaConfirm"
              @cancel="showAreaPicker = false"
            />
          </van-popup> -->
        <div v-if="addressInfo.id" class="address_area">
          <div
            class="van-cell van-cell--center van-cell--clickable van-cell--borderless van-contact-card van-contact-card--add"
          >
            <div class="address_info">
              <div class="edit_info">
                <span class="van-cell__title van-field__label">Ship to</span>
                <div class="info">
                  <span
                    >{{ addressInfo.receiver_name }}&emsp;{{
                      addressInfo.phone
                    }}</span
                  >
                  <span class="edit" @click="checkAddress(addressInfo)">
                    <i class="van-badge__wrapper van-icon van-icon-edit"
                  /></span>
                </div>
              </div>
              <p>
                {{ addressInfo.address_detail }} {{ addressInfo.room }}
                <span v-if="addressInfo.house"
                  >, Apartment: {{ addressInfo.house }}</span
                >
                <span v-if="addressInfo.gate_number"
                  >, Gate: {{ addressInfo.gate_number }}</span
                >
              </p>
            </div>
          </div>
          <van-cell-group>
            <van-field
              v-model="comment"
              rows="3"
              type="textarea"
              label="Comment"
              placeholder=" Which building? Which room? How to find the building? How to enter the building?"
            />
          </van-cell-group>
        </div>
        <div
          v-else
          class="address_area"
          :class="addressRequest ? '' : 'warn_class'"
        >
          <van-contact-card
            type="add"
            add-text="Add address"
            @click="onAddAddress"
          />
          <div v-if="!addressRequest" class="van-field__error-message">
            Please add address
          </div>
        </div>
      </div>
      <div class="content_area other_cost">
        <div class="summary_title">
          Purchase Summary
          <div v-if="feeResult" class="fee_box">
            <img src="../assets/img/fee.png" alt="" srcset="" />
            <div>Free basic freight for over ${{ $filters.priceHandle(fee) }}</div>
          </div>
        </div>
        <div class="other">
          <span class="price_title">Subtotal ( {{ subItems }} items): </span>
          <span class="money">${{ $filters.priceHandle(subTotal) }}</span>
        </div>
        <div class="other">
          <span class="price_title">Delivery:</span>
          <span :class="subTotal > fee ? 'money through_line' : 'money'">${{ $filters.priceHandle(freight) }}</span>
        </div>
        <div class="tip_content">
          <div class="summary_title tip_weight">
            Delivery Tip
            <span class="money">${{ Number(tip).toFixed(2) }}</span>
          </div>
          <div class="set_tips">
            <span
              class="tips"
              :class="tip == 1 ? 'select_tips' : ''"
              @click="tipsChange(1)"
              >$1</span
            >
            <span
              class="tips"
              :class="tip == 2 ? 'select_tips' : ''"
              @click="tipsChange(2)"
              >$2</span
            >
            <span
              class="tips"
              :class="tip == 3 ? 'select_tips' : ''"
              @click="tipsChange(3)"
              >$3</span
            >
            <div class="handle">
              <van-stepper
                v-model.number="tip"
                disable-input
                :min="0"
                @plus="plusChange(item, index)"
                @minus="minusChange(item, index)"
              />
            </div>
          </div>
          <div class="other point_dec">
            <span class="price_title"
              >Yaaami Points ({{ availablePoints }}):</span
            >
            <span class="money">
              - ${{ $filters.priceHandle(availablePoints) }}</span
            >
          </div>
        </div>
        <div class="other">
          <span class="price_title">Credit card fees (3%+$0.3):</span>
          <span class="money">${{ $filters.priceHandle(creditCardFee) }}</span>
        </div>
        <div class="other finall_total">
          <span>Final Payment Amount</span>
          <span class="money">${{ $filters.priceHandle(allPrice) }}</span>
        </div>
        <div v-if="amount !== '' && amount !== null" class="point_toast">
          <span
            ><span class="toast_color">*</span> Yaaami points can cover up to
            {{ amount }}% total payment</span
          >
        </div>
      </div>
      <div class="content_area">
        <template v-if="methodColumns.length > 0">
          <van-field
            v-model="methodCode"
            readonly
            clickable
            name="picker"
            label="Payment card"
            placeholder="Payment card"
            @click="showMethodPicker = true"
          />
        </template>
        <template v-else>
          <div
            class="add_card_input"
            :class="cardRequest ? '' : 'card_request'"
          >
            <van-button
              icon="plus"
              block
              plain
              round
              type="primary"
              @click="addPaymentCard"
            >
              Add payment card
            </van-button>
          </div>
        </template>
        <!-- eslint-disable-next-line vue/no-v-model-argument -->
        <van-popup v-model:show="showMethodPicker" position="bottom">
          <van-picker
            :columns="methodColumns"
            @confirm="onMethodConfirm"
            @cancel="showMethodPicker = false"
          />
        </van-popup>
      </div>
      <van-submit-bar
        currency="$"
        :loading="loading"
        :price="allPrice"
        button-text="Submit"
        @submit="onSubmitDialog"
      />
    </div>
    <nav-bar />
    <add-card
      :add-dialog-show="addDialogShow"
      @closeAddDialog="closeAddDialog"
      @addCardSuccess="addCardSuccess"
    />
    <!-- eslint-disable-next-line vue/no-v-model-argument -->
    <van-dialog
      v-model:show="confirmInfoDialog"
      title="Confirm info"
      :show-cancel-button="true"
      :before-close="beforeClose"
      @confirm="addInfoConfirm"
      @cancel="addInfoCancel"
    >
      <div class="confirm_info">
        <div class="tit border">This delivery address is:</div>
        <div class="address">
          {{ addressInfo.address_detail }} {{ addressInfo.room }}
        </div>
        <div v-if="addressInfo.house" class="house">
          <p>- an apartment</p>
          <p>- room#: {{ addressInfo.house }}</p>
        </div>
        <div v-else class="house">- not an apartment</div>
        <p v-if="addressInfo.gate_number" class="gate border">
          - Gate code: {{ addressInfo.gate_number }}
        </p>
        <p v-else class="gate border">- no gate code</p>
        <div class="checkbox">
          <van-checkbox
            v-model="correctInfoBox"
            :checked-color="primaryColor"
            shape="square"
            @change="checkboxChange"
          >
            Note that missing info will result in delivery failure at your own
            responsibility.
          </van-checkbox>
          <div v-if="pleaseCheck" class="error_title">
            Please check this box and confirm the address information
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, onMounted, toRefs, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Toast, Notify } from "vant";
import navBar from "@/components/NavBar";
import AddCard from "../components/AddCard.vue";
import {
  getAddressList,
  createOrder,
  getCurrentPosition,
  zipCodeSearch,
  getFee,
} from "@/service/user";
import { getTotalPoint, getPoints } from "@/service/point";
import { stripePay } from "../mixin/stripePay.js";
import moment from "moment";
import { getLocal, setLocal } from "@/common/js/utils";

export default {
  name: "Home",
  components: {
    navBar,
    AddCard,
  },
  mixins: [stripePay],
  setup() {
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      addDialogShow: false,
      loading: false,
      allPrice: 0,
      cost: 0,
      // areaCode: '',
      // areaCodeRequest: true,
      addressRequest: true,
      cardRequest: true,
      // showAreaPicker: false,
      // areaColumns: [],
      // areaInfoList: [],
      dateCode: "",
      dateColumns: ["Wednesday", "Saturday"],
      showDatePicker: false,
      goodsList: [],
      addressInfo: {},
      currentPosition: {},
      addressId: "",
      proTimeArr: [],

      curPayMethod: null,
      methodCode: "",
      showMethodPicker: false,
      methodColumns: [],
      payMethods: [],
      payParams: {},
      orderId: "",
      noticeText: "",
      tip: 1,
      freight: 0,
      taxation: 0,
      creditCardFee: 0, //信用卡费用 New add
      sale_time: "",
      subTotal: 0,
      finalFreight:0,
      subItems: 0,
      noSubmit: false,
      comment: "",
      confirmInfoDialog: false,
      creditLock: false,
      correctInfoBox: false,
      pleaseCheck: false,
      availablePoints: 0,
      zipCode: "", // 邮编
      zipCodeError: "Please enter the zip code!",
      zipCodeRequest: true, // 邮编验证
      fee: 0, // 满减金额
      feeResult: false, // 满减条件
      amount: null, // 积分比例
    });
    onMounted(async () => {
      state.addressId = route.query.addressId;
      state.orderId = route.query.orderId;
      var info = sessionStorage.getItem("submitInfo");
      if (info) {
        state.goodsList = JSON.parse(info);
        var allPrice = 0;
        state.subItems = 0;
        state.subTotal = 0;
        state.goodsList.forEach((item) => {
          allPrice += item.num * item.price;
          state.sale_time = item.sale_time;
          state.stock_id = item.product_stock_id;
          state.subItems += Number(item.num);
          state.subTotal += item.num * item.price;
          // state.noticeText = 'Delivery date: ' + moment(item.sale_time).format('MM/DD')
          state.noticeText = "";
        });
        state.cost = allPrice;
        sumAllPrice();
        state.noSubmit = false;
      } else {
        state.noSubmit = true;
      }
      getAllAddressList();
      getPoint();
      var posi = sessionStorage.getItem("position");
      if (posi) {
        state.currentPosition = JSON.parse(sessionStorage.getItem("position"));
      } else {
        getGoogleLocation();
      }
      getAllPaymentMothodsByCustomer();
      var code = localStorage.getItem("zipCode");
      console.log(code);
      if (code) {
        state.zipCode = code;
        handleZipCode();
      }
      getFullMinus();
      // getPoint()
      // getAreaList()
    });

    watch(
      () => state.zipCode,
      () => {
        if (state.timer) {
          clearTimeout(state.timer);
        }
        state.timer = setTimeout(() => {
          handleZipCode();
        }, 1500);
      }
    );

    const setTotalPoint = async () => {
      const res = await getTotalPoint({});
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: "#ad0000",
          background: "#ffe1e1",
        });
      } else {
        const obj = res.data.data;
        state.availablePoints = obj.available_points;
        sumAllPrice();
      }
    };
    const checkboxChange = (val) => {
      if (val) {
        state.pleaseCheck = false;
      }
    };
    const beforeClose = () => {
      if (!state.correctInfoBox) {
        state.pleaseCheck = true;
        return false;
      }
    };
    const addInfoConfirm = () => {
      if (!state.correctInfoBox) {
        state.pleaseCheck = true;
        return false;
      } else {
        state.confirmInfoDialog = false;
        onSubmit();
      }
    };
    const addInfoCancel = () => {
      state.correctInfoBox = false;
      state.confirmInfoDialog = false;
    };
    // const getAreaList = async ()=> {
    //   try {
    //     const res = await areaList({})
    //     console.log(res)
    //     if (res.data.code) {
    //       Notify({ type: 'danger', message: positionObj.errorInfo });
    //     } else {
    //       const list = res.data.data
    //       let nameList = []
    //       list.forEach(item => {
    //         nameList.push(item.area_name)
    //         if (item.area_name == state.areaCode) {
    //           state.freight = item.freight
    //         }
    //       })
    //       state.areaColumns = nameList
    //       state.areaInfoList = list
    //       sumAllPrice()
    //     }
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }
    const getAllPaymentMothodsByCustomer = async () => {
      state.payMethods = await stripePay.methods.getPayMethods();
      if (state.payMethods && state.payMethods.length) {
        state.curPayMethod = state.payMethods[0];
        var arr = [];
        state.payMethods.forEach((item) => {
          var str = item.card.brand + " ****" + item.card.last4;
          arr.push(str);
        });
        state.methodColumns = arr;
        state.methodCode =
          state.payMethods[0].card.brand +
          " ****" +
          state.payMethods[0].card.last4;
      } else {
        state.curPayMethod = null;
      }
    };
    const onMethodConfirm = (item, index) => {
      if (state.payMethods && state.payMethods.length) {
        state.methodCode = item;
        state.curPayMethod = state.payMethods[index];
      }
      state.showMethodPicker = false;
    };
    const getGoogleLocation = async () => {
      try {
        const { res } = await getCurrentPosition({ considerIp: "true" });
        if (res.status === 200) {
          state.currentPosition = res.data.location;
        } else {
          Notify({ type: "danger", message: res.errorInfo });
        }
      } catch (err) {
        console.log(err);
      }
    };
    // 返回
    const onClickLeft = () => {
      router.go(-1);
    };
    const onAddAddress = () => {
      if (state.zipCodeRequest && state.zipCode !== "") {
        router.push({ path: `/addAddress` });
      } else {
        Toast(state.zipCodeError);
        state.zipCodeRequest = false;
        return false;
      }
      // else {
      //   Toast('Please select area！');
      //   state.areaCodeRequest = false
      //   return false
      // }
    };
    // const onAreaConfirm =(item) => {
    //   state.areaCode = item
    //   state.areaCodeRequest = true
    //   state.areaInfoList.forEach(ele => {
    //     if (ele.area_name == item) {
    //       state.freight = ele.freight
    //     }
    //   })
    //   sessionStorage.setItem('areaCode',state.areaCode)
    //   sumAllPrice()
    //   state.showAreaPicker = false
    // }
    const sumAllPrice = () => {
      // Tax费用
      state.taxation = (state.cost * state.taxation) / 100;

      state.finalFreight = state.subTotal > state.fee ? 0 : state.freight

      // 汇总 信用卡费计算要用的总数
      let totalForCreditCardFee = state.cost + state.tip * 100 + state.finalFreight;
      // 信用卡费 [不转为美元 过滤器会把美分转为美元展示的]
      state.creditCardFee = Math.ceil(totalForCreditCardFee * 0.03 + 30);
      // console.log('state.creditCardFee',state.creditCardFee)
      // 总金额
      // const all = state.cost + state.tip * 100 + state.freight + state.taxation
      // 新的 临时 不添加 Tax费用
      const all =
        state.cost + state.tip * 100 + state.finalFreight + state.creditCardFee;
      // 不算小费的金额
      // const noTip = state.cost + state.freight + state.taxation
      // 积分最高抵扣金额
      const deduction = (all * state.amount) / 100;
      if (deduction > state.availablePoints) {
        state.allPrice = all - state.availablePoints;
      } else {
        state.availablePoints = deduction;
        state.allPrice = all - deduction;
      }
      // state.allPrice = state.cost + state.tip * 100 + state.freight + state.taxation - state.availablePoints
    };
    const onDateConfirm = (item) => {
      state.dateCode = item;
      state.showDatePicker = false;
    };
    const checkAddress = (item) => {
      router.push({
        path: `/addressList`,
        query: { source: "submit", id: item.id },
      });
    };
    const getAllAddressList = async () => {
      Toast.loading({
        message: "loading...",
        forbidClick: true,
      });
      const res = await getAddressList({});
      const list = res.data.data;
      let defaultTemp = false;
      for (var i = 0; i < list.length; i++) {
        var item = list[i];
        if (state.addressId) {
          if (item.id == state.addressId) {
            state.addressInfo = item;
            break;
          }
        } else {
          if (item.status === 1) {
            defaultTemp = true;
            state.addressInfo = item;
            break;
          }
        }
      }
      if (!state.addressId && !defaultTemp && list.length) {
        state.addressInfo = list[0];
      }
      Toast.clear();
    };
    const tipsChange = (tip) => {
      state.tip = tip;
      sumAllPrice();
    };
    const plusChange = async () => {
      state.tip++;
      sumAllPrice();
    };
    const minusChange = async () => {
      state.tip--;
      sumAllPrice();
    };
    const onSubmitDialog = () => {
      if (state.noSubmit) {
        Toast("Please reselect the product！");
        return false;
      }

      if (!state.zipCodeRequest || state.zipCode === "") {
        Toast(state.zipCodeError);
        state.zipCodeRequest = false;
        return false;
      }
      // if (!state.areaCode) {
      //   Toast('Please select area！');
      //   state.areaCodeRequest = false
      //   return false
      // }
      if (!state.addressInfo.id) {
        Toast("Please select address！");
        state.addressRequest = false;
        return false;
      }
      var payArr = state.payMethods;
      if (!payArr || !payArr.length) {
        // 去绑卡
        state.addDialogShow = true;
        return false;
      }

      state.confirmInfoDialog = true;
    };
    const onSubmit = async () => {
      if (state.noSubmit) {
        Toast("Please reselect the product！");
        return false;
      }

      if (!state.zipCodeRequest || state.zipCode === "") {
        Toast(state.zipCodeError);
        state.zipCodeRequest = false;
        return false;
      }
      // if (!state.areaCode) {
      //   Toast('Please select area！');
      //   state.areaCodeRequest = false
      //   return false
      // }
      if (!state.addressInfo.id) {
        Toast("Please select address！");
        state.addressRequest = false;
        return false;
      }
      var list = [...state.goodsList];
      list.forEach((item) => {
        item.num = Number(item.num);
      });
      var linkCode = getLocal("linkCode");
      var time = Date.parse(new Date()).toString(); // 获取到毫秒的时间戳，精确到毫秒
      time = time.substr(0, 10); // 精确到秒
      state.payParams = {
        expected_time: Number(time),
        address_id: state.addressInfo.id,
        cost: state.cost,
        long: state.currentPosition.lng,
        lat: state.currentPosition.lat,
        products: list,
        area_code: state.zipCode,
        tip: state.tip,
        taxation: state.taxation,
        freight: state.freight,
        comment: state.comment,
        sale_time: state.sale_time,
        link_code: linkCode,
        points: state.availablePoints,
        no_need_fee: state.fee, // 满减
      };
      var payArr = state.payMethods;
      if (!payArr || !payArr.length) {
        // 去绑卡
        state.addDialogShow = true;
      } else {
        // 去支付
        if (state.orderId) {
          goPay(state.orderId);
        } else {
          createOrderFun();
        }
      }
    };
    const createOrderFun = async () => {
      var params = { ...state.payParams };
      params.tip = state.payParams.tip * 100;
      params.deliver_cost = state.payParams.freight;
      params.tax_cost = state.payParams.taxation;
      params.phone = state.addressInfo.phone;
      params.receiver_name = state.addressInfo.receiver_name;
      params.email = state.addressInfo.email;
      params.address_detail = state.addressInfo.address_detail;
      params.room = state.addressInfo.room;
      params.house = state.addressInfo.house;
      params.gate_number = state.addressInfo.gate_number;
      params.no_need_fee = state.payParams.no_need_fee; // 满减

      Toast.loading({
        message: "Processing payment now. Please wait...",
        forbidClick: true,
      });
      const res = await createOrder(params);
      if (res.data.code) {
        Toast.clear();
        Notify({
          message: res.data.message,
          color: "#ad0000",
          background: "#ffe1e1",
        });
      } else {
        var oid = res.data.data.o_id;
        state.orderId = oid;
        setLocal("linkCode", "");
        goPay(oid);
      }
    };
    const goPay = async (oid) => {
      // var item = state.payParams.cost + state.payParams.freight + state.payParams.taxation
      var item =
        state.payParams.cost + state.finalFreight + state.creditCardFee;
      console.log("item---", item);
      // return;
      const customer = await stripePay.methods.getCustomer();
      const params = {
        item: item,
        tip: state.payParams.tip * 100,
        deliver_cost: state.payParams.freight,
        // tax_cost: state.payParams.taxation,
        order_id: oid,
        customer_id: customer.customer_id,
        points: state.availablePoints,
      };
      stripePay.methods.createPay(params, state.curPayMethod, router);
    };
    const addPaymentCard = () => {
      state.addDialogShow = true;
    };
    const closeAddDialog = () => {
      state.addDialogShow = false;
    };
    const addCardSuccess = () => {
      getAllPaymentMothodsByCustomer();
      closeAddDialog();
    };
    // 失去焦点时获取运费金额
    const handleZipCode = async () => {
      if (state.zipCode !== "") {
        state.zipCodeRequest = true;
        const res = await zipCodeSearch({ zip_code: state.zipCode });
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: "#ad0000",
            background: "#ffe1e1",
          });
        } else {
          if (res.data.data === null) {
            state.zipCodeError =
              "Sorry, we can't deliver to " + state.zipCode + " area yet";
            state.zipCodeRequest = false;
          } else {
            state.freight = res.data.data && res.data.data.freight;
            state.taxation = res.data.data.tax;
            localStorage.setItem("zipCode", state.zipCode);
            sumAllPrice();
          }
        }
      } else {
        state.zipCodeError = "Please enter the zip code!";
        state.zipCodeRequest = false;
      }
    };
    // 是否有满减
    const getFullMinus = async () => {
      const res = await getFee({});
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: "#ad0000",
          background: "#ffe1e1",
        });
      } else {
        const fee = res.data.data;
        if (fee !== 0) {
          state.fee = fee;
          state.feeResult = true;
        }
      }
    };
    // 获取积分比例
    const getPoint = async () => {
      const res = await getPoints({});
      if (res.data.code) {
        Notify({
          message: res.data.message,
          color: "#ad0000",
          background: "#ffe1e1",
        });
      } else {
        state.amount = res.data.data;
        setTotalPoint();
      }
    };
    return {
      ...toRefs(state),
      onClickLeft,
      onAddAddress,
      onSubmit,
      createOrderFun,
      goPay,
      // onAreaConfirm,
      onDateConfirm,
      checkAddress,
      getAllAddressList,
      getGoogleLocation,
      closeAddDialog,
      addCardSuccess,
      getAllPaymentMothodsByCustomer,
      onMethodConfirm,
      addPaymentCard,
      plusChange,
      minusChange,
      tipsChange,
      sumAllPrice,
      // getAreaList,
      addInfoConfirm,
      addInfoCancel,
      onSubmitDialog,
      beforeClose,
      checkboxChange,
      setTotalPoint,
      handleZipCode,
      getFullMinus,
    };
  },
};
</script>
<style lang="less">
@import "../common/style/mixin";
.submit_order {
  padding-bottom: 50px;
  .van-field__error-message {
    font-size: 0.3733rem;
  }
  .van-submit-bar {
    position: static;
    margin-top: 0.2667rem;
    .van-submit-bar__bar {
      box-sizing: border-box;
    }
  }
  .van-picker__toolbar {
    max-width: 800px;
    margin: auto;
    .van-picker__cancel,
    .van-picker__confirm {
      font-size: 0.4rem;
      font-weight: 500;
    }
  }
  .van-nav-bar__right {
    .van-nav-bar__text {
      font-weight: 700;
      color: @primary;
      font-size: 0.48rem;
    }
  }
  .van-nav-bar__title {
    margin-left: 1.3333rem;
  }
  .content {
    width: 100%;
    overflow: auto;
    //height: calc(100vh - 100px);
    //margin-bottom: 100px;

    height: auto;
    //padding-bottom: 50px;
    max-height: calc(100vh - 100px);

    //height: 100vh;
    //padding-bottom: 100px;
    box-sizing: border-box;
    .select_area::before {
      position: absolute;
      left: 8px;
      color: #ee0a24;
      font-size: 14px;
      content: "*";
    }
    .content_area {
      margin-top: 0.2667rem;
    }
    .address_area {
      .address_info {
        p {
          margin: 0;
          span {
            margin-right: 0.4rem;
          }
        }
        .edit_info {
          width: 100%;
          display: flex;
          flex-direction: row;
          margin-bottom: 0.1333rem;
          .info {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .edit {
            color: @primary;
            font-weight: 700;
            font-size: 0.64rem;
          }
        }
      }
      .van-contact-card--add .van-cell__left-icon {
        color: @primary;
      }
      .van-contact-card::before {
        background: none;
      }
      .van-field__error-message {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #fff;
        padding: 0 0.5rem;
        font-size: 0.3733rem;
      }
    }
    .goods_list {
      // padding: .266667rem /* 20/75 */;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 0.266667rem /* 20/75 */;
      .goods_item {
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        background: #fff;
        padding: 0.266667rem /* 20/75 */;
        align-items: center;
        border-bottom: 1px solid #f2f3f5;
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 0;
        }
        .goods_radio {
          margin-right: 0.1333rem;
          display: flex;
          align-items: center;
        }
        .left {
          width: 2rem /* 180/75 */;
          height: 2rem /* 180/75 */;
          overflow: hidden;
          display: flex;
          align-items: center;
          border-radius: 0.2667rem;
          img {
            width: 100%;
          }
        }
        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          justify-content: space-around;
          padding: 0.1rem 0 0.1rem 0.4rem;
          .name {
            width: 100%;
            line-height: 0.8rem;
            margin: 0;
            font-size: 0.426667rem /* 32/75 */;
            font-weight: 500;
          }
          .des {
            font-size: 0.373333rem /* 28/75 */;
            width: 100%;
            text-align: right;
            color: #ed6a0c;
          }
          .all_price {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            .price {
              font-size: 0.453333rem /* 34/75 */;
              font-weight: 500;
              i {
                font-style: normal;
                font-size: 0.3733rem;
              }
            }
          }
        }
      }
    }
  }
  .add_card_input {
    width: 100%;
    padding: 0.2667rem 0.2667rem;
    box-sizing: border-box;
    background: #fff;
    .van-button--plain.van-button--primary {
      border: 1px solid @primary;
      color: @primary;
    }
  }
  .card_request {
    .van-button--plain.van-button--primary {
      border: 1px solid #ee0a24;
      color: #ee0a24;
    }
    background: #f0e68c;
  }
  .other_cost {
    width: 100%;
    background: #fff;
    .summary_title {
      width: 100%;
      height: 1rem;
      line-height: 1rem;
      margin: 0;
      padding: 0 0.4rem;
      box-sizing: border-box;
      font-size: 0.4rem;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        font-weight: 500;
      }
      .fee_box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 25px;
          height: 25px;
        }
        div {
          font-size: 12px;
          color: #666;
          margin-top: 6px;
        }
      }
    }
    .tip_weight {
      font-weight: 500 !important;
    }
    .other {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background: #fff;
      font-size: 0.4rem;
      font-weight: 500;
      box-sizing: border-box;
      padding: 0 0.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .finall_total {
      height: 1.4rem;
      line-height: 1.4rem;
      font-weight: 700;
      font-size: 0.42rem;
    }
    .point_toast {
      position: relative;
      top: -10px;
      padding: 0 0.4rem;
      .toast_color {
        color: red;
      }
    }
    .set_tips {
      width: 100%;
      height: 50px;
      line-height: 49px;
      background: #fff;
      font-size: 0.36rem;
      font-weight: 500;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      .tips {
        width: 1.2rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #f2f3f5;
        margin-right: 0.2667rem;
        border-radius: 0.1333rem;
        text-align: center;
      }
      .handle {
        .van-stepper__minus,
        .van-stepper__plus {
          width: 1rem;
          height: 0.8rem;
          line-height: 0.8rem;
        }
        .van-stepper__input {
          width: 1rem;
          height: 0.8rem;
          line-height: 0.8rem;
        }
      }
      .select_tips {
        background: @primary;
        color: #fff;
      }
    }
    .tip_content {
      border-bottom: 1px solid #f2f3f5;
      border-top: 1px solid #f2f3f5;
      width: calc(100% - 0.8rem);
      margin: 0 0.4rem;
      .summary_title {
        padding: 0;
      }
    }
    .price_title {
      margin-right: 0.1333rem;
      background: #fff;
      font-size: 0.36rem;
    }
  }
  .point_dec {
    color: #ee0a24;
    padding-top: 10px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-top: 1px solid #f2f3f5;
    margin-bottom: 10px;
  }
  // @media screen and (min-width: 1000px) {
  //   .other_cost {
  //     width: 10rem;
  //     margin: auto;
  //   }
  // }
  .confirm_info {
    width: 100%;
    padding: 0.4rem;
    box-sizing: border-box;
    font-size: 0.4267rem;
    line-height: 0.8rem;
    .border {
      border-bottom: 1px solid #f4f5f7;
    }
    .tit {
      color: #666;
    }
    p {
      margin: 0;
      color: #ed6a0c;
    }
    .checkbox {
      margin-top: 0.2667rem;
      .error_title {
        color: #ee0a24;
        line-height: 0.5333rem;
        margin-top: 0.1333rem;
      }
    }
  }
  .warn_class {
    background: #f0e68c;
    .van-cell {
      background: #f0e68c;
    }
  }
}
</style>
