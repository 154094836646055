/* eslint-disable no-async-promise-executor */
/* eslint-disable vue/no-v-model-argument */
<!--
 * @Author: your name
 * @Date: 2021-04-20 18:10:10
 * @LastEditTime: 2021-04-27 16:14:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \user-frontend\src\views\Home.vue
-->
<template>
  <div class="order_detail">
    <!--分享引导框开始-->
    <van-overlay class-name="overlay" :show="wxShare">
      <!--  eslint-disable-next-line vue/html-self-closing -->
      <img class="share_point" src="./../assets/img/share.png" alt="" />
      <!--  eslint-disable-next-line vue/html-self-closing -->
      <img class="share_btn" src="./../assets/img/share_know.png" alt="" @click="wxShare = false" />
    </van-overlay>
    <!--分享引导框结束-->
    <van-nav-bar title="Order details" left-arrow @click-left="onClickLeft" />
    <div class="order_content">
      <div v-if="orderInfo.status == 6" class="eval_title">
        <van-notice-bar
          :scrollable="false"
          :text="'Comments on products will earn points ' + pointValue + '.'"
        />
      </div>
      <div class="content">
        <div v-if="queryType" class="top_title">
          <span>To ship</span>
        </div>
        <div v-else class="top_title">
          <span :class="orderInfo.status == 0 ? 'not_paid' : ''">{{
            orderStatus[orderInfo.status]
          }}</span>
          <van-button
            v-if="orderInfo.status == 0"
            class="go_pay"
            size="small"
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            round
            @click="goPay"
          >
            Pay
          </van-button>
        </div>
        <p class="order_id_style">
          Order id: {{ orderId }}
        </p>
        <p v-if="submitInfo && submitInfo.length > 0">
          Delivery date: {{ $filters.saleTimeFilter(submitInfo[0].sale_time) }}
        </p>
        <p>Created time: {{ $filters.timeFilter(orderInfo.created_at) }}</p>
      </div>
      <div v-if="orderInfo.status <= 6" class="go_share">
        <p class="share_title">
          Share with your friends, to get rebate
        </p>
        <van-button
          class="go_share_btn"
          block
          color="linear-gradient(to right, #43c6ac, #1d953f)"
          round
          @click="goShare"
        >
          Share
        </van-button>
      </div>
      <div class="user_info good_list">
        <div class="title">
          Item info<span><b>{{ subItems }}</b> items</span>
        </div>
        <div v-for="(item, index) in goodsList" :key="index" class="goods_item">
          <div class="left">
            <!--  eslint-disable-next-line vue/html-self-closing -->
            <img :src="item.pictures" alt="" srcset="" />
          </div>
          <div class="right">
            <div class="name_con">
              <div class="name">
                {{ item.product_name }}
                <div v-if="item.status > 0">
                  ({{ statusStockFilter(item.status) }}), nums: {{ item.stock_out_refund_num }}
                </div>
              </div>
              <van-button
                v-if="orderInfo.status == 6"
                class="to_evaluate"
                size="small"
                round
                :color="primaryColor"
                plain
                type="primary"
                @click="toEvaluate(item)"
              >
                Review
              </van-button>
            </div>
            <div class="all_price">
              <span class="price"><i>$</i>{{ $filters.priceHandle(item.price) }} *
                {{ item.num }}</span>
              <span class="price"><i>$</i>{{ $filters.priceHandle(item.price) * item.num }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="user_info">
        <div class="good_total">
          <div>
            <span>Subtotal ( <b>{{ subItems }}</b> items): </span><b>${{ $filters.priceHandle(subTotal) }}</b>
          </div>
          <div>
            <span>Delivery: </span><b :class="(orderInfo.no_need_fee >0 && subTotal > orderInfo.no_need_fee) ? 'through_line' : ''">${{ $filters.priceHandle(orderInfo.deliver_cost) }}</b>
          </div>
          <div>
            <span>Delivery tip: </span><b>${{ $filters.priceHandle(orderInfo.tip) }}</b>
          </div>
          <div>
           <span>Credit card fees (3%+$0.3): </span><b>${{ $filters.priceHandle(creditCardFee) }}</b>
          </div>
          <div v-if="feePoints" class="fee-points">
            <span>Yaaami Points ({{ feePoints }}): </span><b>-${{ $filters.priceHandle(feePoints) }}</b>
          </div>
          <div class="total">
            <span>Total: </span>
            <b>${{
              $filters.priceHandle(
                subTotal + final_deliver_cost + orderInfo.tip + feePoints + creditCardFee
              ) }}</b>
          </div>
          <div v-if="refundAmount > 0" class="total refund_total">
            <span>Refund Total: </span><b>${{ $filters.priceHandle(refundAmount) }}</b>
          </div>
        </div>
      </div>
      <div class="user_info">
        <div class="title">
          Delivery
          <span
            v-if="orderInfo.status <= 3"
            class="edit_address"
            @click="editAddressFun"
          >
            Edit address
          </span>
        </div>
        <div class="detail">
          <div class="info">
            <p>Name: {{ orderInfo.receiver_name }}</p>
            <p>Phone: {{ orderInfo.phone }}</p>
          </div>
          <p>Address: {{ orderInfo.address_detail }} {{ orderInfo.room }}</p>
          <p v-if="orderInfo.comment">
            Comment: {{ orderInfo.comment }}
          </p>
          <div v-if="orderInfo.house" class="house">
            <p>- an apartment</p>
            <p>- room#: {{ orderInfo.house }}</p>
          </div>
          <div v-else class="house">
            - not an apartment
          </div>
          <p v-if="orderInfo.gate_number" class="gate border">
            - Gate code: {{ orderInfo.gate_number }}
          </p>
          <p v-else class="gate border">
            - no gate code
          </p>
        </div>
      </div>
      <div class="share_title">
        These people helped you for rebate
      </div>
      <div class="border_box follow_list">
        <div v-for="(item, index) in buyList" :key="index" class="follow_item">
          <div class="user">
            <div class="img">
              <!-- eslint-disable-next-line vue/html-self-closing -->
              <img :src="item.headSrc" alt="" />
            </div>
            <div class="info">
              <span class="name">{{ item.name }}</span>
              <span class="time">{{ item.time }}</span>
            </div>
          </div>
          <div class="status">
            Bought
          </div>
        </div>
        <div v-if="buyList.length == 0" class="no_invite">
          <p>No people yet</p>
          <span class="no_friend"><van-icon name="friends-o" /></span>
        </div>
      </div>
      <div class="handle_btn">
        <!-- <van-button
          v-if="deadLine"
          type="primary"
          color="#ee0a24"
          round
          block
          plain
          hairline
          @click="goCancelOrder"
        >
          Cancel order
        </van-button> -->
        <!-- v-else -->
        <van-button
          type="primary"
          color="#ee0a24"
          round
          block
          plain
          hairline
          @click="goRefund"
        >
          Request refund
        </van-button>
        <!-- <van-button type="primary"
        round block plain hairline @click="goHome">Go order list</van-button> -->
      </div>
    </div>
    <nav-bar />
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-share-sheet v-model:show="showShare" title="Share it with friends now" :options="shareOptions" @select="onSelectShare" />
    <!--  eslint-disable-next-line vue/no-v-model-argument -->
    <van-popup v-model:show="refundShow" position="bottom" :style="{ height: '65%' }">
      <div class="eval_show">
        <div class="title">
          Request refund
        </div>
        <van-cell-group>
          <van-field
            v-model="refundComment"
            type="textarea"
            row="2"
            :autofocus="true"
            label="Comment"
            placeholder=""
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="refundEmail"
            type="text"
            :autofocus="true"
            label="Email"
            placeholder=""
          />
        </van-cell-group>
        <van-uploader
          v-model="fileList"
          accept="image/*"
          :after-read="onFileRead"
          :before-delete="onDelete"
          class="uploadImg"
          multiple
          :max-count="6"
        />
        <van-button
          round
          type="primary"
          class="publish"
          :color="primaryColor"
          :loading="btnLoading"
          plain
          block
          @click="goCancel"
        >
          Submit
        </van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
// import { getLocal } from '@/common/js/utils'
import { reactive, onMounted, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Toast, Dialog, Notify } from 'vant'
import navBar from '@/components/NavBar'
import {
  getOrderDetail,
  getAddressList,
  editOrderAddress,
  jsAuth,
  applyRefund
} from '@/service/user'
import { getMoreGoodsList } from '@/service/product'
import { useStore } from 'vuex'
import { stripePay } from '../mixin/stripePay.js'
import { shareInfo, getBuyOrderList, getShareAddress } from '@/service/point'
import { uploadImg } from '@/service/user'
import moment from 'moment'

export default {
  name: 'Home',
  components: {
    navBar
  },
  mixins: [stripePay],
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      orderId: '',
      deadLine: false, // true 在截止日期之前 false 在截止日期之后
      goodsList: [],
      orderInfo: {},
      orderStatus: {},
      addressInfo: {},
      submitInfo: [],
      queryType: '',
      subItems: 0,
      subTotal: 0,
      final_deliver_cost:0,
      sale_time: '',
      refundAmount: 0,
      appraise_status: 0,
      showShare: false,
      shareOptions: [
        {
          name: 'Facebook',
          icon: require('./../assets/img/fb.png')
        },
        {
          name: 'Copy url',
          icon: 'link'
        }
        // {
        //   name: 'Wechat',
        //   icon: require('./../assets/img/wx.png')
        // },
      ],
      linkCode: '',
      pointList: [],
      pointValue: '',
      creditCardFee: 0,
      feePoints: 0,
      buyList: [],
      wxShare: false,
      share: false,
      refundShow: false,
      refundComment: '',
      refundEmail: '',
      fileList: [], // 图片上传
      uploadImgList: []
    })
    onMounted(async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      state.orderId = route.query.orderId
      state.queryType = route.query.type
      state.orderStatus = store.state.orderStatus
      state.share = route.query.share ? route.query.share : false
      if (state.orderId && !state.share) {
        getOrderInfo()
        getShareInfo()
        await store.dispatch('getPointList')
        state.pointList = store.state.pointList || []
        state.pointList.filter(v => {
          if (v.user_points_type === 2) {
            state.pointValue = v.count
          }
        })
      } else if (state.orderId && state.share) {
        router.push({
          path: `/orderShare`,
          query: { linkCode: route.query.linkCode }
        })
      } else {
        Notify({
          message: 'Route error',
          color: '#ad0000',
          background: '#ffe1e1'
        })
      }
    })
    const onClickLeft = () => {
      if (state.queryType === 'done') {
        router.push({ path: `/order` })
      } else {
        router.go(-1)
      }
    }
    const goHome = () => {
      router.push({ path: `/order` })
    }
    const getShareInfo = async() => {
      try {
        const res = await shareInfo({
          promote_type: 2,
          order_id: state.orderId
        })
        if (res.data.code) {
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const obj = res.data.data
          state.linkCode = obj.link
          orderBuyList()
        }
      } catch (err) {
        Notify({ type: 'danger', message: 'Server error' })
      }
    }
    const editAddressFun = () => {
      sessionStorage.setItem('orderInfo', JSON.stringify(state.orderInfo))
      router.push({ path: `/editOrder` })
    }
    const updateOrderAddress = async() => {
      try {
        Toast.loading({
          message: 'loadin...',
          forbidClick: true
        })
        const params = {
          order_id: state.orderId
        }
        const res = await editOrderAddress(params)
        Toast.clear()
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: '#ad0000',
            background: '#ffe1e1'
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
    const getOrderInfo = async() => {
      try {
        Toast.loading({
          message: 'loadin...',
          forbidClick: true
        })
        const res = await getOrderDetail({ order_id: state.orderId })
        Toast.clear()
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: '#ad0000',
            background: '#ffe1e1'
          })
        } else {
          state.orderInfo = res.data.data.order_info
          state.appraise_status = res.data.data.appraise_status
          state.refundAmount = res.data.data.amount
          state.goodsList = res.data.data.order_detail
          state.sale_time = state.orderInfo.sale_time
          const deadTimeCount = moment(state.sale_time).diff(moment(), 'days')
          if (deadTimeCount > 0) {
            state.deadLine = true
          } else {
            state.deadLine = false
          }
          console.log(state.deadLine)
          console.log(state.orderInfo)
          state.share = res.data.data.share
          var userPoints = res.data.data.user_points
          if (userPoints && userPoints.length) {
            state.feePoints = userPoints[0].Amount
          }
          // getAddressData()
          // searchProInfo()
          state.subItems = 0
          state.subTotal = 0
          state.goodsList.forEach(item => {
            state.subItems += item.num
            state.subTotal += item.num * item.price
          })

          state.final_deliver_cost = (state.orderInfo.no_need_fee > 0 && state.subTotal > state.orderInfo.no_need_fee) ? 0 : state.orderInfo.deliver_cost

          // 增加 信用卡费用回显
          let totalForCreditCardFee = state.subTotal + state.final_deliver_cost + state.orderInfo.tip;
          // 信用卡费 [不转为美元 过滤器会把美分转为美元展示的]
          state.creditCardFee = Math.ceil(totalForCreditCardFee * 0.03 + 30);
        }
      } catch (err) {
        console.log(err)
      }
    }
    const searchProInfo = async() => {
      var idArr = []
      var obj = {}
      state.goodsList.forEach(item => {
        obj[item.product_id] = item.num
        idArr.push(item.product_id)
      })
      var idStr = idArr.join(',')
      try {
        const res = await getMoreGoodsList({
          id_str: idStr,
          sale_time: '',
          // sale_time: state.sale_time
        })
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: '#ad0000',
            background: '#ffe1e1'
          })
        } else {
          var list = res.data.data
          state.subItems = 0
          state.subTotal = 0
          list.forEach(item => {
            item.num = obj[item.id]
            state.subItems += item.num
            state.subTotal += item.num * item.price
          })
          state.submitInfo = list
        }
      } catch (err) {
        console.log(err)
      }
    }
    const getAddressData = async() => {
      try {
        const res = await getAddressList({
          address_id: state.orderInfo.address_id
        })
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: '#ad0000',
            background: '#ffe1e1'
          })
        } else {
          const list = res.data.data
          state.addressInfo = list[0]
        }
      } catch (err) {
        console.log(err)
      }
    }
    const goRefund = () => {
      state.refundComment = ''
      state.refundEmail = ''
      state.fileList = []
      state.uploadImgList = []
      state.refundShow = true
    }
    const goCancelOrder = () => {
      Dialog.confirm({
        title: 'Cancel',
        message: ' Are you sure to send cancel order?'
      })
        .then(async() => {
          // on confirm
          const res = await applyRefund({
            order_id: state.orderId,
            status: 7
          })
          if (res.data.code) {
            Notify({
              message: res.data.message,
              color: '#ad0000',
              background: '#ffe1e1'
            })
          } else {
            Toast.success('Apply success')
          }
        })
        .catch(() => {
          // on cancel
        })
    }
    const goCancel = () => {
      Dialog.confirm({
        title: 'Cancel',
        message: ' Are you sure to send refund request?'
      })
        .then(async() => {
          // on confirm
          const res = await applyRefund({
            order_id: state.orderId,
            email: state.refundEmail,
            comment: state.refundComment,
            pic_url_list: state.uploadImgList
          })
          if (res.data.code) {
            Notify({
              message: res.data.message,
              color: '#ad0000',
              background: '#ffe1e1'
            })
          } else {
            Toast.success('Apply success')
            onClickLeft()
          }
        })
        .catch(() => {
          // on cancel
        })
    }
    const goPay = () => {
      sessionStorage.setItem('submitInfo', JSON.stringify(state.submitInfo))
      router.push({
        path: '/submitOrder',
        query: { addressId: state.orderInfo.address_id, orderId: state.orderId }
      })
    }
    const goShare = () => {
      state.showShare = true
      // router.push({ path: `/orderShare`, query: {linkCode: state.linkCode} })
    }
    const onSelectShare = async item => {
      const name = item.name
      var url = ''
      var appId = ''
      if (window.location.host === 'yaaami.com') {
        url = `https://yaaami.com/api/share/${state.orderId}/2/fbshare`
        console.log(url)
        console.log('========================')
        // url = 'https://yaaami.com/orderShare?linkCode=' + state.linkCode
        appId = '288178443052249'
      } else {
        url = 'http://test.yaaami.com/orderShare?linkCode=' + state.linkCode
        appId = '227444888941530'
      }
      if (name === 'Facebook') {
        // 线上：288178443052249
        // 测试：227444888941530
        // eslint-disable-next-line no-undef
        FB.init({
          appId: appId,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v2.12'
        })

        const res = await getShareAddress({
          order_id: state.orderId,
          promote_type: 2
        })
        if (res.data.code) {
          Notify({
            message: res.data.message,
            color: '#ad0000',
            background: '#ffe1e1'
          })
        } else {
          console.log('=======接口成功')
          console.log('分享地址' + res.data.data)
          // eslint-disable-next-line no-undef
          FB.ui({
            method: 'share',
            mobile_iframe: true,
            href: res.data.data
          })
        }

        // console.log('直接window.open')
        // window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(url), '_blank', 'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350')
        // window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(url))

        // console.log(encodeURIComponent(url))
        // console.log('share==========================' + url)
        // console.log('FB======================分享结束')
        // eslint-disable-next-line no-undef
      } else if (name === 'Wechat') {
        const url = window.location.href.split('#')[0]
        const res = await jsAuth({ url })
        Toast(res.data.code)
        wxShare(res.data.data)
      } else {
        var oInput = document.createElement('input')
        oInput.value = url
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        Toast('Copy success！')
      }
    }
    const toEvaluate = item => {
      item.orderId = state.orderId
      item.appraise_status = state.appraise_status
      localStorage.setItem('evaluateObj', JSON.stringify(item))
      router.push({ path: `/evaluatePage` })
    }
    const orderBuyList = async() => {
      Toast.loading({
        message: 'loading...',
        forbidClick: true
      })
      try {
        const res = await getBuyOrderList({
          link_code: state.linkCode,
          points_type: 2
        })
        if (res.data.code) {
          Toast.clear()
          Notify({ type: 'danger', message: res.data.message })
        } else {
          const list = res.data.data
          var newLists = []
          list.forEach(element => {
            const num = Math.floor(Math.random() * 8)
            var obj = {}
            obj.headSrc = require('./../assets/img/head/head' + num + '.png')
            obj.name = element.promote.user_name
            obj.created_at = element.promote.created_at
            newLists.push(obj)
          })
          state.buyList = newLists
          Toast.clear()
        }
      } catch (err) {
        Toast.clear()
        Notify({ type: 'danger', message: 'Server error' })
      }
    }

    const wxShare = data => {
      Toast('Share start')
      state.showShare = false
      // eslint-disable-next-line no-undef
      wx.config({
        debug: true,
        appId: data.app_id,
        timestamp: data.timestamp,
        nonceStr: data.nonce_str,
        signature: data.signature,
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline'
        ]
      })

      // eslint-disable-next-line no-undef
      wx.ready(() => {
        Toast('Share ready')
        state.wxShare = true
        // eslint-disable-next-line no-undef
        wx.onMenuShareAppMessage({
          title: state.share.title,
          desc: state.share.desc,
          link: window.location.href.split('#')[0] + '&share=true',
          imgUrl: state.pic_url,
          success: () => {
            state.wxShare = false
            Toast('Share success')
            // 用户点击了分享后执行的回调函数
          }
        })
        // eslint-disable-next-line no-undef
        wx.onMenuShareTimeline({
          title: state.share.title,
          link:
            window.location.href.split('#')[0] +
            '&share=true&linkCode=' +
            state.linkCode,
          imgUrl: state.pic_url,
          success: () => {
            state.wxShare = false
            Toast('Share success')
            // 用户点击了分享后执行的回调函数
          }
        })
      })

      // eslint-disable-next-line no-undef
      wx.error((res) => {
        Toast(res.errMsg)
        // Toast('Share fail')
      })
    }

    const onFileRead = file => {
      // eslint-disable-next-line
      return new Promise(async () => {
        file.status = 'uploading'
        file.message = 'Uploading...'
        // 获取后缀
        const uploadFileNameArr = file.file.name.split('.')
        var uploadFileName = '.' + uploadFileNameArr[uploadFileNameArr.length - 1]
        var timestamp = new Date().getTime()
        var tempfile = new File([file.file], timestamp + uploadFileName)
        const formData = new FormData()
        formData.append('upload', tempfile)
        const res = await uploadImg(formData)
        if (res.data.code) {
          file.status = 'failed'
          file.message = 'Failed'
        } else {
          file.status = ''
          file.message = ''
          const url = res.data.data.f
          state.uploadImgList.push(url)
        }
      })
    }

    const onDelete = (file, detail) => {
      const fileIndex = detail.index
      state.uploadImgList = state.uploadImgList.splice(fileIndex, 1)
      console.log(state.uploadImgList)
      return true
    }

    const statusStockFilter = (val) => {
      if (val === 1) {
        return 'Full refund for out of stock'
      } else if (val === 2) {
        return 'Refund for out of stock part'
      }
    }
    return {
      ...toRefs(state),
      goRefund,
      getOrderInfo,
      onClickLeft,
      getAddressData,
      goCancel,
      goCancelOrder,
      goPay,
      goHome,
      goShare,
      searchProInfo,
      toEvaluate,
      updateOrderAddress,
      editAddressFun,
      onSelectShare,
      getShareInfo,
      orderBuyList,
      onFileRead,
      onDelete,
      statusStockFilter
    }
  }
}
</script>
<style lang="less">
@import "../common/style/mixin";
.order_detail {
  // overflow: hidden;
  overflow-y: scroll;
  .overlay {
    z-index: 2005;
    .share_point {
      width: 200px;
      position: absolute;
      right: 0;
    }
    .share_btn {
      width: 200px;
      position: absolute;
      right: 0;
      top: 250px;
    }
  }
  .order_content {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 96px);
  }
  .go_share {
    width: calc(100% - 0.5333rem);
    margin: 0.2667rem auto;
    overflow: auto;
    background: #fff;
    padding: 0.2667rem;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.3733rem;
    color: #666;
    .share_title {
      margin: 0;
      margin-bottom: 0.2667rem;
      font-size: 0.4267rem;
      font-weight: 500;
      text-align: center;
    }
    .go_share_btn {
      font-weight: bolder;
    }
  }
  .content {
    width: calc(100% - 0.5333rem);
    margin: 0.2667rem auto;
    overflow: auto;
    background: #fff;
    padding: 0.2667rem;
    box-sizing: border-box;
    border-radius: 5px;
    .top_title {
      width: 100%;
      height: 1.0667rem;
      line-height: 1.0667rem;
      font-size: 0.4267rem;
      color: #333;
      font-weight: 700;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .go_pay {
        width: 80px;
      }
    }
    p {
      font-size: 0.4rem;
      color: #666;
      margin: 0;
      line-height: 0.8rem;
    }
    .order_id_style {
      font-size: 0.32rem;
    }
  }
  .good_list {
    width: 100%;
    padding: 0 0.2667rem;
    box-sizing: border-box;
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .goods_item {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      background: #fff;
      padding: 0.266667rem /* 20/75 */ 0;
      align-items: center;
      border-bottom: 1px solid #f2f3f5;
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
      .goods_radio {
        margin-right: 0.1333rem;
        display: flex;
        align-items: center;
      }
      .left {
        width: 2rem /* 180/75 */;
        height: 2rem /* 180/75 */;
        overflow: hidden;
        display: flex;
        align-items: center;
        border-radius: 0.2667rem;
        img {
          width: 100%;
        }
      }
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 0.1rem 0 0.1rem 0.4rem;
        .name_con {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.3rem;
          .name {
            line-height: 0.8rem;
            margin: 0;
            font-size: 0.426667rem /* 32/75 */;
            font-weight: 500;
          }
          .to_evaluate {
            // width: 3rem;
            margin-left: 0.2rem;
          }
        }
        .des {
          font-size: 0.373333rem /* 28/75 */;
          width: 100%;
          text-align: right;
          color: #ed6a0c;
        }
        .all_price {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          .price {
            font-size: 0.453333rem /* 34/75 */;
            font-weight: 500;
            i {
              font-style: normal;
              font-size: 0.3733rem;
            }
          }
        }
      }
    }
  }
  .good_title {
    font-size: 0.3733rem;
    height: 0.9333rem;
    line-height: 0.9333rem;
  }
  .not_paid {
    color: crimson;
  }
  .goodItem {
    width: 100%;
    padding-top: 0.2667rem;
    p {
      margin: 0;
      margin-bottom: 0.2667rem;
      font-size: 0.3733rem;
    }
  }
  .good_total {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.2rem 0;
    font-size: 0.38rem;
    line-height: 0.8rem;
    width: 100%;
    div {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .total {
      border-top: 1px solid #ebeef5;
      padding-top: 0.1333rem;
    }
    .fee-points {
      color: red;
    }
    .refund_total {
      color: red;
    }
    b {
      margin-left: 0.1rem;
    }
  }
  .title {
    height: 1rem;
    line-height: 1rem;
    font-size: 0.4267rem;
    border-bottom: 1px solid #ebeef5;
  }
  .user_info {
    width: calc(100% - 0.5333rem);
    margin: 0.2667rem auto;
    overflow: auto;
    background: #fff;
    padding: 0.1333rem 0.2667rem;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.3733rem;
    color: #666;
    .detail {
      padding-top: 0.2667rem;
      p {
        margin: 0;
        margin-bottom: 0.2667rem;
      }
      .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .edit_address {
      float: right;
      font-size: 0.3733rem;
      color: @primary;
      font-weight: bolder;
      border-radius: 30px;
      border: 1px solid @primary;
      padding: 0 10px;
      line-height: 0.8rem;
      height: 0.8rem;
    }
  }
  .handle_btn {
    width: 100%;
    padding: 0.2667rem;
    padding-bottom: 1.2rem;
    box-sizing: border-box;
    .van-button {
      margin-bottom: 0.2667rem;
    }
  }
  .share_title {
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    padding: 0 0.4rem;
    font-size: 0.4267rem;
    font-weight: 500;
    box-sizing: border-box;
  }
  .border_box {
    width: calc(100% - 0.5333rem);
    margin: 0.2667rem auto;
    overflow: auto;
    background: #fff;
    padding: 0.2667rem;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .follow_list {
    box-sizing: border-box;
    // padding: 0 0.4rem 0.4rem;
    background: #fff;
    .follow_item {
      width: 100%;
      height: 1.28rem;
      border-bottom: 1px solid #f4f5f7;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.15rem 0;
      .user {
        display: flex;
        flex-direction: row;
        align-items: center;
        .img {
          margin-right: 0.2667rem;
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          font-size: 0.3733rem;
          .name {
            margin-bottom: 0.1rem;
            color: #333;
          }
          .time {
            color: #999;
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
      .status {
        height: 24px;
        padding: 0.1rem 0.2667rem;
        text-align: center;
        background: #fff5e8;
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        color: #df2c2e;
      }
    }
  }
  .no_invite {
    width: 100%;
    height: 3.6667rem;
    p {
      width: 100%;
      font-size: 0.4267rem;
      color: #666;
      text-align: center;
    }
    span {
      font-size: 2rem;
      text-align: center;
      display: block;
      color: #666;
    }
  }
  .eval_show {
    padding-top: 0 !important;
    .title {
      text-align: center;
      font-weight: 600;
    }

    .uploadImg {
      margin-top: 10px;

      .van-uploader__wrapper {
        justify-content: space-between;
      }
      .van-uploader__upload {
        background: lightskyblue;
      }
      .van-uploader__upload-icon {
        color: #000;
      }
    }
  }
}
</style>
